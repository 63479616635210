<div class="loginBackground">
  <div class="inner_cont">
    <!-- <div class="left_part">
      <div class="logo_cont">
        <div class="image" ></div>
      </div>
    </div>
    <div class="verticle_line">

    </div> -->
    @if(switcher =='main'){
    <div class="right_part">
      <div class="upper_text_cont">
        <div class="header1">Welcome</div>
        <div class="header6">Please Login to Admin Panel</div>
      </div>
      <div class="form_cont">
        <form
          class="form"
          [formGroup]="formStepOne"
          (ngSubmit)="submit()"
        >
          <div
            class="username"
            data-validate="email is required"
          >
            @if (submittedForm && f['email'].errors) {
            <div>
              @if (f['email'].errors['required']) {
              <p class="pop__error">
                {{ "*Email is required"}}
              </p>
              }
            </div>
            }
            <input
              class="form-control"
              type="text"
              formControlName="email"
              name="username"
              placeholder="Enter email"
            >

          </div>
          <div
            class="password"
            data-validate="Password is required"
          >
            @if (submittedForm && f['password'].errors) {
            <div>
              @if (f['password'].errors['required']) {
              <p class="pop__error">
                {{ "*Password is required"}}
              </p>
              }
            </div>
            }
            <input
              class="form-control"
              type="password"
              formControlName="password"
              name="pass"
              placeholder="Enter password"
            >

          </div>

          <div
            class="code"
            data-validate="code is required"
          >
            <input
              class="form-control"
              type="text"
              formControlName="twoFoToken"
              name="code"
              placeholder="Enter code"
            >
          </div>
          <div>
            <a
              class="forgot"
              (click)="openForgotPopup()"
            >Forgot your password?</a>
          </div>
          <div class="submit_button">
            <button
              class="action_button"
              [disabled]="isLoggin"
            >
              Login {{isLoggin?timer:''}}
            </button>
          </div>

        </form>
      </div>
    </div>
    }

    @if(switcher =='set'){
    <div class="right_part">
      <div class="upper_text_cont">
        <div class="header6">Set your password</div>
      </div>
      <div class="form_cont">
        <form
          class="form"
          [formGroup]="setPass"
          (ngSubmit)="setPassSubmit()"
        >
          <div
            class="password"
            data-validate="Password is required"
          >
            @if (submittedPassForm && _setPass['password'].errors) {
            <div>
              @if (_setPass['password'].errors['required']) {
              <p class="pop__error">
                {{ "*Password is required"}}
              </p>
              }
            </div>
            }

            <input
              class="form-control"
              type="text"
              formControlName="password"
              name="pass"
              placeholder="Enter password"
              [ngClass]="{'errorField': _setPass['password'].dirty && (!_setPass['password']?.value?.match('^(?=.*[A-Z])') 
              || !_setPass['password']?.value?.match('(?=.*[a-z])')
              || !_setPass['password']?.value?.match('(.*[0-9].*)')
              || !_setPass['password']?.value?.match('(?=.*[!@#$%^&*])')
              || !_setPass['password']?.value?.match('.{8,}')),
              'goodField': _setPass['password'].dirty && (
                  _setPass['password']?.value?.match('^(?=.*[A-Z])') 
                  && _setPass['password']?.value?.match('(?=.*[a-z])')
                  && _setPass['password']?.value?.match('(.*[0-9].*)')
                  && _setPass['password']?.value?.match('(?=.*[!@#$%^&*])')
                  && _setPass['password']?.value?.match('.{8,}')
                )
            }"
            >

          </div>
          <div
            class="password"
            data-validate="Password is required"
          >
            @if (submittedPassForm && _setPass['repeatPassword'].errors) {
            <div>
              @if (_setPass['repeatPassword'].errors['required']) {
              <p class="pop__error">
                {{ "*Password is required"}}
              </p>
              }

              @if (_setPass['repeatPassword'].errors['matching']) {
              <p class="pop__error">
                {{ "*Passwords should be the same"}}
              </p>
              }
            </div>
            }
            <input
              class="form-control"
              type="text"
              formControlName="repeatPassword"
              name="pass"
              placeholder="Confirm password"
              [ngClass]="{'errorField':_setPass['repeatPassword'].dirty && _setPass?.['repeatPassword']?.errors?.['matching'],'goodField':_setPass['repeatPassword'].dirty && !_setPass?.['repeatPassword']?.errors?.['matching'] }"
            >

          </div>
          @if ( _setPass['password'].dirty) {
          <div>
            <p class="special_pop__error">
              <span
                class="passPop starPop"
                [ngClass]="{'goodLine': _setPass['password']?.value?.match('^(?=.*[A-Z])')}"
              >At least one uppercase
                letter.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _setPass['password']?.value?.match('(?=.*[a-z])')}"
              >At least one lowercase
                letter.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _setPass['password']?.value?.match('(.*[0-9].*)')}"
              >At least one
                digit.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _setPass['password']?.value?.match('(?=.*[!@#$%^&*])')}"
              >At least one
                special
                character (&#64;$!%*#?&^).</span>
              <span
                class="passPop endPop"
                [ngClass]="{'goodLine': _setPass['password']?.value?.match('.{8,}')}"
              >At least 8 characters
                long.</span>
            </p>
          </div>
          }
          <div
            class="submit_button"
            [ngClass]="{'submitMargin':!_setPass['password'].dirty}"
          >
            <button class="action_button">
              Confirm
            </button>
          </div>

        </form>
      </div>
    </div>
    }

    @if(switcher =='reset'){
    <div class="right_part">
      <div class="upper_text_cont">
        <div class="header6">Reset your password</div>
      </div>
      <div class="form_cont">
        <form
          class="form"
          [formGroup]="resetPass"
          (ngSubmit)="resetSubmit()"
        >
          <div
            class="password"
            data-validate="Password is required"
          >
            @if (submittedPassForm && _resetPass['password'].errors) {
            <div>
              @if (_resetPass['password'].errors['required']) {
              <p class="pop__error">
                {{ "*Password is required"}}
              </p>
              }
            </div>
            }
            <input
              class="form-control"
              type="text"
              formControlName="password"
              name="pass"
              placeholder="Enter new password"
              [ngClass]="{'errorField': _resetPass['password'].dirty && (!_resetPass['password']?.value?.match('^(?=.*[A-Z])') 
                || !_resetPass['password']?.value?.match('(?=.*[a-z])')
                || !_resetPass['password']?.value?.match('(.*[0-9].*)')
                || !_resetPass['password']?.value?.match('(?=.*[!@#$%^&*])')
                || !_resetPass['password']?.value?.match('.{8,}')),
                'goodField': _resetPass['password'].dirty && (
                  _resetPass['password']?.value?.match('^(?=.*[A-Z])') 
                  && _resetPass['password']?.value?.match('(?=.*[a-z])')
                  && _resetPass['password']?.value?.match('(.*[0-9].*)')
                  && _resetPass['password']?.value?.match('(?=.*[!@#$%^&*])')
                  && _resetPass['password']?.value?.match('.{8,}')
                )
                }"
            >
          </div>
          <div
            class="password"
            data-validate="Password is required"
          >
            @if (submittedPassForm && _resetPass['repeatPassword'].errors) {
            <div>
              @if (_resetPass['repeatPassword'].errors['required']) {
              <p class="pop__error">
                {{ "*Password is required"}}
              </p>
              }
              @if (_resetPass['repeatPassword'].errors['matching']) {
              <p class="pop__error">
                {{ "*Passwords should be the same"}}
              </p>
              }
            </div>
            }
            <input
              class="form-control"
              type="text"
              formControlName="repeatPassword"
              name="pass"
              placeholder="Confirm password"
              [ngClass]="{'errorField':_resetPass['repeatPassword'].dirty && _resetPass?.['repeatPassword']?.errors?.['matching'],'goodField':_resetPass['repeatPassword'].dirty && !_resetPass?.['repeatPassword']?.errors?.['matching'] }"
            >

          </div>
          @if ( _resetPass['password'].dirty) {
          <div>
            <p class="special_pop__error">
              <span
                class="passPop starPop"
                [ngClass]="{'goodLine': _resetPass['password']?.value?.match('^(?=.*[A-Z])')}"
              >At least one uppercase
                letter.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _resetPass['password']?.value?.match('(?=.*[a-z])')}"
              >At least one lowercase
                letter.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _resetPass['password']?.value?.match('(.*[0-9].*)')}"
              >At least one
                digit.</span>
              <span
                class="passPop"
                [ngClass]="{'goodLine': _resetPass['password']?.value?.match('(?=.*[!@#$%^&*])')}"
              >At least one
                special
                character (&#64;$!%*#?&^).</span>
              <span
                class="passPop endPop"
                [ngClass]="{'goodLine': _resetPass['password']?.value?.match('.{8,}')}"
              >At least 8 characters
                long.</span>
            </p>
          </div>
          }
          <div
            class="submit_button"
            [ngClass]="{'submitMargin':!_resetPass['password'].dirty}"
          >
            <button class="action_button">
              Confirm
            </button>
          </div>

        </form>
      </div>
    </div>
    }

    @if(switcher =='qr'){
    <div class="right_part">
      <div class="upper_text_cont">
        <div class="header1">Please scan the QR code</div>
        <div class="header6">for the security of your credentials</div>
      </div>
      <div class="form_cont">
        <div style="display: flex; justify-content: center">
          <img
            [src]="imageUrl"
            alt=""
          />
        </div>
        <div class="submit_button">
          <button
            class="action_button"
            (click)="qrLogin()"
            style="margin-top: 20px;"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
    }

  </div>
</div>

@if (isForgotPopUp) {
<div class="background">
  <div class="forgotPopUp">
    <form
      class="form"
      [formGroup]="passwordRecovery"
      (ngSubmit)="submitPasswordRecovery()"
    >
      <div class="header">
        Password Recovery
      </div>
      <div class="body">
        <p>Enter your email address to receive a password recovery link.</p>
        <div>
          <label class="sub_title3">EMAIL</label>

          <input
            class="form-control"
            formControlName="_email"
            type="email"
          >
          @if (submittedPassForm && pass['_email'].errors) {
          <div>
            @if (pass['_email'].errors['required']) {
            <p class="pop__error">
              {{ "*Email is required"}}
            </p>
            }
            @if (pass['_email'].errors['email']) {
            <p class="pop__error">
              {{ "*Enter valid email"}}
            </p>
            }
          </div>
          }
        </div>

      </div>
      <div class="action_buttons">
        <div class="columns">
          <button
            type="button"
            class="second_button btn title5"
            (click)="onCancelAction()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="action_button btn title5"
          >
            Send
          </button>

        </div>
      </div>
    </form>

  </div>
</div>
}


@if (loader) {
<div class="loader">
  <img
    src="../assets/loader.gif"
    alt=""
  >
</div>
}