// @ts-nocheck
import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(private title: Title, private meta: Meta) {}

  updateTitle(title: string, keys?: any) {
    if (title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string, keys?: any) {
    // if (description) {
    // 	setTimeout(() => {
    // 		keys = this.getKeys(keys)
    // 		this.meta.updateTag({ name: 'description', content: this.translate.instant(description, keys) })
    // 	}, 300)
    // }
  }

  updateMetaTags(metaTags: MetaDefinition[]) {
    // metaTags.forEach(m => {
    // 	if (m.content) {
    // 		const keys = this.getKeys(m['keys'])
    // 		m.content = this.translate.instant(m.content, keys)
    // 	}
    // 	delete m['keys']
    // 	return this.meta.updateTag(m)
    // })
  }
}
