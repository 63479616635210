import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, UrlSerializer } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ApiService } from "../services/api.service";
import { SharedData } from "../services/sharedData.service";
import { forkJoin, Subject, Subscription } from "rxjs";
import { StoreService } from "@services/store.service";
import { PanelAccessConfigService } from "@services/panelAccessConfig.service";
import { environment } from "../../environments/environment";
import { AppInitService } from "../modules/app/app-init.service";
import { debounceTime, delay, takeUntil } from "rxjs/operators";
import { FormsModule } from "@angular/forms";
import { SocketService } from "@services/socket.service";
import Validation from "@worker/helper/form_validation";
import { CommonModule } from "@angular/common";
let that: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [AppInitService, ApiService],
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  public message: string = "";
  public baseUrl = environment.apiEndpoint;
  public TwoFactor: boolean = true;
  public loader: boolean = false;
  public submittedForm: boolean = false;
  public submittedPassForm: boolean = false;
  public timer: number = 10;
  public isLoggin: boolean = false;
  private myInterval: any;
  public switcher: string = "main"
  public qrCode: boolean = false;

  public StrongPasswordRegx: RegExp =
    /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

  AuthService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  ApiService: ApiService = inject(ApiService);
  SharedData: SharedData = inject(SharedData);
  StoreService: StoreService = inject(StoreService);
  PanelAccessConfigService: PanelAccessConfigService = inject(PanelAccessConfigService);
  cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  AppInitService: AppInitService = inject(AppInitService);
  SocketService: SocketService = inject(SocketService);

  public formStepOne: FormGroup = new FormGroup({
    email: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
    twoFoToken: new FormControl(),
    // twilio_sms: new FormControl(),
  });

  public formStepTwo: FormGroup = new FormGroup({
    twoFoToken: new FormControl(),
  });

  public passwordRecovery: FormGroup = new FormGroup({
    _email: new FormControl("", [Validators.required, Validators.email]),
  });
  public setPass: FormGroup = new FormGroup({
    password: new FormControl("", {
      validators: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
    }),
    repeatPassword: new FormControl("", [Validators.required]),
  }, {
    validators: [Validation.match('password', 'repeatPassword')]
  });
  public resetPass: FormGroup = new FormGroup({
    password: new FormControl("", {
      validators: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
    }),
    repeatPassword: new FormControl("", [Validators.required]),
  }, {
    validators: [Validation.match('password', 'repeatPassword')]
  });

  public isForgotPopUp: boolean = false;
  public token_qr_code: string = "";
  public email: string = "";
  public _email: string = "";
  public imageUrl: string = "";
  public qrSetting: any;
  private unsubscribe$ = new Subject<void>();
  constructor() {
    that = this;
  }
  myStopFunction() {
    clearInterval(this.myInterval);
  }
  myTimer() {
    that.timer--;
    console.log(that.timer);
    if (that.timer == 0) {
      that.myStopFunction();
      that.timer = 10;
      setTimeout(() => {
        that.isLoggin = false;
        that.cdRef.detectChanges();
      }, 100);
    }
    that.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }

  log() {
    console.log("LoginComponent");
    return true;
  }

  ngAfterViewInit(): void { }

  ngOnInit(): void {
    // window.onbeforeunload = null;
    console.log(window.location.href.includes("action=forgot"))
    if (window.location.href.includes("action=forgot")) {
      this.switcher = 'reset'
    }
    if (window.location.href.includes("action=reset")) {
      this.switcher = 'set'
    }
    if (window.location.href.includes("qrCode=true")) {
      this.switcher = 'qr'
    }

    if (this.StoreService.getUser()) {
      this.StoreService.resetStore();
    }
  }
  private background = 0;

  get f(): { [key: string]: AbstractControl } {
    return this.formStepOne.controls;
  }

  get pass(): { [key: string]: AbstractControl } {
    return this.passwordRecovery.controls;
  }

  get _setPass(): { [key: string]: AbstractControl } {
    return this.setPass.controls;
  }



  get _resetPass(): { [key: string]: AbstractControl } {
    return this.resetPass.controls;
  }

  public submit(): void {
    this.ApiService.debil = "tvari";
    this.formStepOne.value.twoFoToken ? "" : (this.formStepOne.value.twoFoToken = "");
    this.submittedForm = true;
    let ikbs_ = false;
    if (this.formStepOne.valid || ikbs_) {
      this.isLoggin = true;
      this.myInterval = setInterval(this.myTimer, 1500);
      this.ApiService.Postmethod(`account/login/`, this.formStepOne.value).subscribe(
        (response) => {
          if (response.token) {
            this.AuthService.setToken(response);
            this.submittedForm = false;
            this.AppInitService.getInfo()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((response) => {
                this.initMenu(response);
              });
            return;
          }
          if (response.tokenSk) {
            localStorage.setItem("temp-session-token", response.tokenSk);
            this.SocketService.tempConnection(this);
            this.loader = true;

            this.SocketService.twilioPush.subscribe((response) => {
              if (response.token) {
                this.AuthService.setToken(response);
                this.submittedForm = false;
                this.AppInitService.getInfo()
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe((response) => {
                    this.initMenu(response);
                  });
                return;
              }
            });
          }
          // if (response.twoFa.twilio_push || response.twoFa.twilio_sms) {
          //   this.qrSetting = response;
          //   this.TwoFactor = !this.TwoFactor;
          //   this.token_qr_code = response.twoFa.token_qr_code;
          //   this.email = response.email;
          // }
        },
        (err) => {
          this.loader = false;

          this.isLoggin = false;
          this.timer = 10;
          this.myStopFunction();
          that.cdRef.detectChanges();

          console.log(err);
        }
      );
    }
  }

  initMenu(response: any) {
    let url = this.PanelAccessConfigService.setCurrentUrl(response[0]);
    this.formStepOne.markAllAsTouched();
    this.formStepOne.reset();
    this.TwoFactor = true;
    this.token_qr_code = "";
    this.email = "";
    this.imageUrl = "";
    localStorage.removeItem("temp-session-token");
    this.isLoggin = false;
    this.timer = 10;
    this.myStopFunction();
    //@ts-ignore
    let url2 = url[0].replace(/\%3f/g, "?").replace(/\%3d/g, "=").replace(/\%3F/g, "?").replace(/\%3D/g, "=");
    this.StoreService.addToStore("route", url2);
    this.router.navigate([`${url}`]);
    this.cdRef.detectChanges();
    this.loader = false;
  }

  checkLocalStorage() {
    return localStorage.getItem(this.StoreService.getUser().email + "path");
  }

  public submitTwo(): void {
    this.formStepOne.value.twoFoToken = this.formStepTwo.value.twoFoToken;
    this.formStepTwo.value;
    this.ApiService.Postmethod(`account/login/`, this.formStepOne.value).subscribe(
      (response) => {
        this.AuthService.setToken(response);
        // this.initMenu()
        // this.SharedData.role_data = response.settings.role_data;
      },
      (err) => console.log(err)
    );
  }

  // public qr() {
  //   this.ApiService.Postmethod(`account/2fa/twilio/qrcode`, {
  //     token_qr_code: this.token_qr_code,
  //     email: this.email,
  //   }).subscribe(
  //     (response) => {
  //       this.imageUrl = response.qr_code;
  //     },
  //     (err) => console.log(err)
  //   );
  // }

  openForgotPopup() {
    this.isForgotPopUp = true
  }

  onCancelAction() {

    this.isForgotPopUp = false
  }

  submitPasswordRecovery() {
    this.submittedPassForm = true
    if (this.passwordRecovery.valid)
      this.ApiService.Postmethod("account/forgot/password", { email: this.passwordRecovery.value._email, action: "forgot" }).subscribe((response) => {
        console.log(response)
        this.SharedData.successMessage("A password recovery link has been sent to your email.")

        this.submittedPassForm = false
        this.isForgotPopUp = false
        this.cdRef.detectChanges()
      })
  }

  //link: "http://192.168.77.59:4200/login/reset?token=m33X4Tp5qxycDYSpuHUCAPP6BqV2JKUFWjLRugrRkarGxGOmD6TdoC8mCeJpUUnzMGvGWg&action=set&email=test@gmail.com"

  setPassSubmit() {
    this.submittedPassForm = true
    if (this.setPass.valid) {
      const link: any = window.location.href
      let token: any
      if (window.location.href) {
        token = link.match(/token=.*&action/gmi)[0].replace("token=", "").replace("&action", "");
        console.log(token)
      }
      this.ApiService.Postmethod(`account/forgot/password/${token}`, { password: this.setPass.value.password, repeatPassword: this.setPass.value.repeatPassword }).subscribe((response) => {
        console.log(response)
        this.submittedPassForm = false
        this.qr(token)
      })
    }
  }

  resetSubmit() {
    console.log('debil');

    this.submittedPassForm = true
    if (this.resetPass.valid) {
      const link: any = window.location.href
      let token
      if (window.location.href) {
        token = link.match(/token=.*&action/gmi)[0].replace("token=", "").replace("&action", "");
      }
      this.ApiService.Postmethod(`account/forgot/password/${token}`, { password: this.resetPass.value.password, repeatPassword: this.resetPass.value.repeatPassword }).subscribe((response) => {
        console.log(response)
        this.SharedData.successMessage("Password set successfully")

        this.submittedPassForm = false
        this.router.navigate(["/login"]);

      })
    }
  }

  public qr(token: any) {
    // this.ApiService.Postmethod(`account/2fa/twilio/qrcode`, {
    this.ApiService.Getmethod(`account/forgot/qr/${token}`)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: any) => {
          this.switcher = 'qr'

          this.imageUrl = response.qr_code;

          this.cdRef.detectChanges()
        },
        error: (err: any) => console.log(err),
      });
  }

  qrLogin() {
    console.log('test')
    this.router.navigate(["/login"]);

  }
}
