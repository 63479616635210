import { Injectable, inject } from "@angular/core";
import { ApiService } from "@services/api.service";
import { AuthService } from "@services/auth.service";
import { PanelAccessConfigService } from "@services/panelAccessConfig.service";
import { StoreService } from "@services/store.service";
import { BehaviorSubject, Observable, forkJoin, throwError } from "rxjs";
import { MetaService } from "@services/meta.service";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";

@Injectable()
export class AppInitService {
  apiService: ApiService = inject(ApiService);
  storeService: StoreService = inject(StoreService);
  authService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  PanelAccessConfigService: PanelAccessConfigService = inject(PanelAccessConfigService);
  MetaService: MetaService = inject(MetaService);

  private readonly mock = new BehaviorSubject<any>({});
  readonly mock$ = this.mock.asObservable();

  //@ts-ignore
  getInfo(): Observable<any> {
    if (window.location.origin.indexOf("ltd-ss") != -1) {
      this.MetaService.updateTitle("PS Admin Panel");
      let favIcon = document.getElementById("favicon");
      //@ts-ignore
      favIcon["href"] = "assets/favicon2.ico";
    }
    if (this.authService.isAuthenticated()) {
      return forkJoin([
        this.apiService.Getmethod(`menu`),
        this.apiService.Getmethod(`account/role`),
        this.apiService.Getmethod(`account/info`),
        this.apiService.Getmethod(`project/interface`),
      ]).pipe(
        map((response) => {
          this.storeService.setUser(response[2]);
          let data = [
            {
              name: "menu",
              value: this.PanelAccessConfigService.formUrl(response[0]),
            },
            {
              name: "role_data",
              value: response[1],
            },
            {
              name: "panel_api",
              value: [response[3].api][0],
            },
            {
              name: "interface",
              value: response[3],
            },
            {
              name: "DD_projects",
              value: response[3].projects.filter((el: any) => !el.tech),
            },
          ];
          this.storeService.addToStore_multiple(data);
          return response;
        }),
        catchError(this.handleError.bind(this))
      );
    } else {
      console.log("notAuthTriggers");
      // this.storeService.notAuthTriggers();
      if (window.location.href.includes('action=reset') || window.location.href.includes('action=forgot') || window.location.href.includes('qrCode=true')) {
        // this.router.navigate([], {
        //   queryParams: {
        //     account_id: item.account_id,
        //     login: item.login,
        //     host_id: item.host_id,
        //     tab: "main_table",
        //     // ikbs: Math.random() * 1000,
        //   },
        //   queryParamsHandling: "merge",
        // });
        // this.router.navigate([window.location.href]);
      } else {
        this.router.navigate(["/login"]);

      }
    }
  }

  handleError(error: HttpErrorResponse | null | any) {
    console.log(error);
    console.log("handleError");
    if (this.router.url !== "/login") {
      this.router.navigate(["/login"]);
      localStorage.removeItem("session-Token");
      history.pushState({ state: 1 }, "State 1", "/login");
      window.location.reload();
    }
    return throwError(() => { });
  }
}

// .subscribe({
//   next: (response) => {
//     this.storeService.addToStore_static('menu', this.PanelAccessConfigService.formUrl(response[0]))
//     this.storeService.addToStore_static('role_data', response[1])
//     this.storeService.setUser(response[2])
//     this.storeService.addToStore_static('panel_api', [response[3].api][0])
//     this.storeService.addToStore_static('interface', response[3])
//     this.storeService.addToStore_static('DD_projects', response[3].projects.filter(el => !el.tech))

//   },
//   error: (error) => console.log(error)
// })
